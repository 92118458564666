import { useContext } from "react"
import { LangContext } from "../../Lang/Provider/Provider"
import { EListFunction, IUseFormResult, useForm } from "@piximind/custom-hook"
import { ResetPassTemplate } from "../../Template"

function ResetPass(): JSX.Element {
  const lang = useContext(LangContext)
  const {
    state: form,
    onChange,
    isFormValid
  }: IUseFormResult = useForm({
    isRealTimeValidation: true,
    data: [
      {
        key: "password",
        value: "",
        rules: [
          {
            function: EListFunction.isNotEmpty,
            messageError: lang.emptyMessage,
            priority: 0
          }
        ],
        successMessage: lang.validationMessage,
        isRealTimeValidation: true
      },
      {
        key: "confirmPassword",
        value: "",
        rules: [
          {
            function: EListFunction.isNotEmpty,
            messageError: lang.emptyMessage,
            priority: 0
          }
        ],
        successMessage: lang.validationMessage,
        isRealTimeValidation: true
      }
    ]
  })
  const resetPass = () => {
    console.log("resetPass")
  }
  const getIsinValidConfirmPassword = () => {
    return (
      form?.password.isInvalid ||
      form?.confirmPassword.isInvalid ||
      form?.password.value !== form?.confirmPassword.value
    )
  }
  const getIsValidConfirmPassword = () => {
    return (
      form?.password.isValid &&
      form?.confirmPassword.isValid &&
      form?.password.value === form?.confirmPassword.value
    )
  }
  return (
    <ResetPassTemplate
      onChange={onChange}
      form={form}
      isFormValid={isFormValid}
      onSubmit={resetPass}
      isResetPass
      getIsinValidConfirmPassword={getIsinValidConfirmPassword}
      getIsValidConfirmPassword={getIsValidConfirmPassword}
    />
  )
}

export default ResetPass
