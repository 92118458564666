import { IKeys } from "../../Interfaces"

class Data {
  private static instance: Data
  constructor() {}

  public static getInstance(): Data {
    if (!Data.instance) {
      Data.instance = new Data()
    }
    return Data.instance
  }
  static getSelectOption() {
    return [
      { label: "test1", value: "test1" },
      { label: "test2", value: "test2" },
      { label: "test3", value: "test3" },
      { label: "test4", value: "test4" },
      { label: "test5", value: "test5" },
      { label: "test6", value: "test6" },
      { label: "test7", value: "test7" },
      { label: "test8", value: "test8" }
    ]
  }
  static getItems(lang:IKeys) {
    return [
      {
        isLeftSecondaryIcon: true,
        icon: "home",
        label: lang.default,
        subItems: [
          {
            label: lang.overview
          },
          {
            label: lang.project
          }
        ]
      },
      {
        isLeftSecondaryIcon: true,
        icon: "shopping-bag",
        label: lang.eCommerce,
        subItems: [
          {
            label: lang.overview
          },
          {
            label: lang.project
          }
        ]
      }
    ]
  }
}

export { Data }
