import { IElementState } from "../../CustomHooks/Interfaces/Interfaces"

class CommonFunction {
    private static instance: CommonFunction;
    constructor() { }
    public static getInstance(): CommonFunction {
        if (!CommonFunction.instance) {
            CommonFunction.instance = new CommonFunction();
        }
        return CommonFunction.instance;
    }
    static getInputProps(state: IElementState) {
        return {
            value: state.value ? state.value.toString() : "",
            isInvalid: state.isInvalid,
            isValid: state.isValid,
            success: state.successMessage,
            error: state.errorMessage,
        }
    }
    static getSelectProps(state: IElementState) {
        let value: Array<string> = []
        if (state.value) {
            if (!Array.isArray(state.value)) {
                value = []
            } else {
                value = state.value
            }
        } else {
            value = []
        }
        return {
            selectValue: value ? value : [],
            isInvalid: state.isInvalid,
            isValid: state.isValid,
            success: state.successMessage,
            error: state.errorMessage,
        }
    }
}

export { CommonFunction }