import { ReactCompareSlider, ReactCompareSliderImage } from 'react-compare-slider';
import WITH from './with SlC-hilight.jpg'
import WITHOUT from './Without SlC-hi-light..jpg'
import { Button } from '@piximind/ds-p-23';
import { useState } from 'react';
const PageTest = (): JSX.Element => {
    const [text, setText] = useState('Paysage')
    const [isPortrait, setIsPortrait] = useState(false)

    const changeDisplayType = () => {
        setIsPortrait(!isPortrait)
        if (text === 'Paysage') {
            setText('Portrait')
        } else {
            setText('Paysage')
        }
    }

    return (
        <div style={{ height: '100%' }}>
            <Button style={{ position: 'absolute', zIndex: 2, left: '20px', top: '30px' }} text={text} onClick={changeDisplayType} />
            <ReactCompareSlider
                portrait={isPortrait}
                itemOne={<ReactCompareSliderImage style={{ height: '100%' }} src={WITH} alt="Image one" />}
                itemTwo={<ReactCompareSliderImage style={{ height: '100%' }} src={WITHOUT} alt="Image two" />}
            />
        </div>
    )
}
export default PageTest
