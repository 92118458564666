import { useContext } from "react"
import { LangContext } from "../../Lang/Provider/Provider"
import { EListFunction, IUseFormResult, useForm } from "@piximind/custom-hook"
import { ForgetPassTemplate } from "../../Template"

function ForgetPass(): JSX.Element {
  const lang = useContext(LangContext)
  const {
    state: form,
    onChange,
    isFormValid
  }: IUseFormResult = useForm({
    isRealTimeValidation: true,
    data: [
      {
        key: "mail",
        value: "",
        rules: [
            {
                function: EListFunction.isNotEmpty,
                messageError: lang.emptyMessage,
                priority: 0
              },
            {
            function: EListFunction.isMail,
            messageError: lang.invalidMailMessage,
            priority: 10
          },
        ],
        successMessage: lang.validationMessage,
        isRealTimeValidation: true
      }
    ]
  })
  const forgetPass = () => {
    console.log("forgetPass")
  }
  return (
    <ForgetPassTemplate
    onChange={onChange}
      form={form}
      isFormValid={isFormValid}
      onSubmit={forgetPass}
      isForgetpass
    />
  )
}

export default ForgetPass
