import { IKeys } from "../../Interfaces";

const lang: IKeys = {
    fr: "Frensh",
    en: "English",
    ar: "Arabic",
    connexion : "Log In",
    connexionTitle : "Log In",
    inscriptionTitle : "Register",
    forgetPass :"Forgot password?",
    register: "Sign up",
    haveAccount : "Do you have already an account",
    identifiant : "Identifier",
    mdp : "Password",
    rememberMe :"Remember me",
    firstName : "First name",
    lastName : "Last name",
    confirmPassword : "Confirm password",
    acceptCondition : "I accept the conditions of use",
    validationMessage: "Yes",
    emptyMessage: "Required Field",
    invalidMailMessage : "it's not an email",
    forgetPassBtn : "Send mail",
    resetPassTitle : "Chage password",
    resetPassBtn : "CHange",
    reliabilityMessage : "Password strength: low",
    ForgetPassTitle : "Find your account ",
    default :"Default",
    overview : "Overview",
    project : "Projects",
    eCommerce : "eCommerce",
    logOut : "Log out"


}
export default lang;