import { Api } from "@piximind/api"
import { Config } from "../../Common"

class AuthAPI {
    _api: Api
    constructor() {
        this._api = new Api(Config.getInstance().API_URL)
    }
    async getUser() {
        return this._api.get("/")
    }
    async login() { }
}
export { AuthAPI }