import { EListFunction, IUseFormResult } from "@piximind/custom-hook"
import { LoginTemplate } from "../../Template"
import { useForm } from "@piximind/custom-hook"
import { useState, useContext } from "react"
import { LangContext } from "../../Lang/Provider/Provider"
import { AuthAPI } from "../../Api"

const Login = (): JSX.Element => {
  const lang = useContext(LangContext)
  const {
    state: form,
    onChange,
    isFormValid
  }: IUseFormResult = useForm({
    isRealTimeValidation: true,
    data: [
      {
        key: "mail",
        value: "",
        rules: [
          {
            function: EListFunction.isNotEmpty,
            messageError: lang.emptyMessage,
            priority: 0
          },
          {
            function: EListFunction.isMail,
            messageError: lang.invalidMailMessage,
            priority: 10
          }
        ],
        successMessage: lang.validationMessage,
        isRealTimeValidation: true
      },
      {
        key: "password",
        value: "",
        rules: [
          {
            function: EListFunction.isNotEmpty,
            messageError: lang.emptyMessage,
            priority: 0
          }
        ],
        successMessage: lang.validationMessage,
        isRealTimeValidation: true
      },
      {
        key: "save",
        value: false,
        rules: [
          {
            function: EListFunction.isTrue,
            messageError: lang.emptyMessage,
            priority: 0
          }
        ],
        isRealTimeValidation: true
      },
    ]
  })

  const login = () => {
    const auth = new AuthAPI()
    const response = auth.login()
  }

  return (
    <LoginTemplate
      onChange={onChange}
      form={form}
      isFormValid={isFormValid}
      onSubmit={login}
      isLogin
    />
  )
}

export default Login
