import { Navbar, Sidebar } from "@piximind/ds-p-23"
import { useDispatch, useSelector } from "react-redux"
import { Outlet } from "react-router-dom"
import { SidebarType } from "@piximind/ds-p-23/lib/esn/Interfaces/Organisms/IOrganismSidebar/IOrganismSidebar"
import { RootState } from "../../Interfaces/Redux"
import { useContext } from "react"
import { LangContext } from "../../Lang/Provider/Provider"
import { Data } from "../../Common"

const PrivateRoute = (): JSX.Element => {
  const lang = useContext(LangContext)

  const showSidebar = useSelector((state: RootState) => state?.sideBar?.show)
  const { height, width } = useSelector((state: RootState) => state?.screen)
  const dispatch = useDispatch()
  return (
    <div style={{ width: width, height: height }} className="ds-w-100 ds-flex-col">
      <div className="ds-w-100 ds-h-100 ds-flex-col">
        <Navbar withButton btnText={lang.logOut} />
        <div className={"ds-w-100 ds-flex ds-flex-grow1"}>
          {showSidebar && <Sidebar type={SidebarType.type1} items={Data.getItems(lang)} />}
          <div className="ds-h-100 ds-flex-grow1">
            <Outlet />
          </div>
        </div>
      </div>
    </div>
  )
}

export default PrivateRoute
