import { IKeys } from "../../Interfaces";

const lang:IKeys = {
    fr: "Français",
    en: "Anglais",
    ar: "Arabe",
    connexion : "Se connecter",
    connexionTitle : "Connexion",
    inscriptionTitle : "Inscription",
    forgetPass :"Mot de passe oublié?",
    register : "S'inscrire",
    haveAccount : " Vous avez déja un compte ?",
    identifiant : "Identifiant",
    mdp : "Mot de passe",
    rememberMe :"Se souvenir de moi",
    firstName : "Prénom",
    lastName : "Nom",
    confirmPassword : "Confirmer mot de passe",
    acceptCondition : "J'accepte les conditions d'utilisation",
    validationMessage:"Oui",
    emptyMessage:"Champ obligatoire",
    invalidMailMessage : "C'est pas un mail",
    forgetPassBtn : "Envoyer un e-mail",
     resetPassTitle : "Changer mot de passe",
     ForgetPassTitle : "Trouvez votre compte",
    resetPassBtn : "Changer",
    reliabilityMessage : "Fiabilité de mot de passe : faible",
    default :"par défaut",
    overview : "aperçu",
    project : "Projets",
    eCommerce : "e-Commerce",
    logOut : "déconnexion "
}
export default lang;